/* eslint-disable vue/no-unused-components */
<template>
  <div class="room-option border-grayLight border rounded-8 overflow-hidden cursor-pointer relative"
       :class="[{ 'active': selectedRoomTypeId === roomType.id }, {'disabled' : isUnavailable }]"
       :disabled="disabled"
       @click="openModal(roomType.id)"
  >
    <div v-if="roomType.id === preferredRoomTypeId" class="room-option-preferred-icon">
      <star-icon size="1.5x" stroke-width="2" stroke="var(--color-primary)" fill="transparent"></star-icon>
    </div>
    <!--    <pre v-html="roomSelectRoomTypeAvailability" />-->
    <!--    <pre v-html="$store.getters.selectedOffers" />-->
    <!--    <pre v-html="$store.getters.roomRequestId" />-->
    <!--    <pre v-html="roomTypeAvailability" />-->
    <!--    <pre v-html="selectedRoomTypeMap" />-->
    <div v-if="selectedRoomTypeId === roomType.id"
         class="grid grid-cols-selectedOffer absolute top-10 left-10 bg-primary text-white font-medium rounded-8 z-50 px-12 py-6 text-14"
    >
      <span class="pr-12 whitespace-no-wrap">{{ $t('roomSelect.selected') }}</span>
      <check-icon size="1.4x" class="text-white"></check-icon>
    </div>
    <div v-if="disabled"
         class="block absolute top-10 left-10 bg-primary text-white font-medium rounded-8 z-50 px-12 py-6 text-14"
    >
      <span>{{ $t('roomSelect.notAvailable') }}</span>
    </div>
    <div class="room-images-wrapper">
      <!-- if no image -->
      <div v-if="isEmpty(roomType.thumbImages)" class="room-image">
        <!--              <img src="../../../assets/img/bell.jpg" class="col-span-2 row-span-3 ">-->
        <img :src="brokenImageUrl" class="col-span-2 row-span-3 " alt="No Image">
        <span class="absolute flex left-0 top-0 right-0 bottom-0 justify-center items-center text-white font-medium">{{ $t('roomSelect.noImage') }}</span>
      </div>
      <!-- if 3 images or less -->
      <div v-else-if="roomType.thumbImages.length <=3" class="room-image">
        <img :src="roomType.thumbImages[0]" :onerror="`this.src='${brokenImageUrl}'`" class="col-span-2 row-span-3 " :alt="roomType.name[locale]">
      </div>
      <!-- if >= 4 images -->
      <div v-else-if="roomType.thumbImages.length >= 4" class="room-images">
        <div class="image col-span-2 row-span-3 ">
          <img :src="roomType.thumbImages[0]" :onerror="`this.src='${brokenImageUrl}'`" :alt="roomType.name[locale]">
        </div>
        <div class="image">
          <img :src="roomType.thumbImages[1]" :onerror="`this.src='${brokenImageUrl}'`" :alt="roomType.name[locale]">
        </div>
        <div class="image">
          <img :src="roomType.thumbImages[2]" :onerror="`this.src='${brokenImageUrl}'`" :alt="roomType.name[locale]">
        </div>
        <div v-if="roomType.thumbImages.length === 4" class="image">
          <img :src="roomType.thumbImages[3]" :onerror="`this.src='${brokenImageUrl}'`" :alt="roomType.name[locale]">
        </div>
        <div v-else class="image last" :data-remaining-images="roomType.thumbImages.length - 4">
          <img :src="roomType.thumbImages[3]" :onerror="`this.src='${brokenImageUrl}'`" :alt="roomType.name[locale]">
        </div>
      </div>
    </div>
    <div class="details pt-18 px-16">
      <div class="flex justify-between">
        <h2 class="font-semibold text-body">
          {{ roomType.name[locale] }}
        </h2>
        <info-icon v-if="!isEmpty(roomType.description[locale])"
                   v-tooltip="{ content: roomType.description[locale] }"
                   size="24"
                   class="text-grayBlue cursor-help"
        ></info-icon>
      </div>
      <div class="specs text-gray text-14 leading-16 mt-8">
        {{ $tc('roomSelect.occupancy', roomType.maxOccupancy, [roomType.minOccupancy, roomType.maxOccupancy]) }}
      </div>
      <div v-if="!isNil(offerMap[roomRequest.id][roomType.id]) && availableRooms <= settings.lowAvailabilityMessageThreshold && availableRooms > 0" class="hurry flex items-center text-error text-body-small mt-9">
        <clock-icon size="14" class="text-warn mr-5"></clock-icon>
        <span>{{ $tc('roomSelect.onlyXroomAvailable', availableRooms, [availableRooms]) }}</span>
      </div>
    </div>
    <div v-if="!isNil(offerMap[roomRequest.id][roomType.id]) && availableRooms > 0" class="details pb-18 px-16">
      <div class="price flex justify-between border-t border-grayLight mt-16 pt-16 text-14 leading-16">
        <template v-if="selectedRoomTypeId === roomType.id && !isNil(selectedOffer)">
          <div v-if="hotel.details.google === true" class="text-gray">
            {{ hotel.details.currency }}&nbsp;{{
              ((selectedOffer.unitPriceSummary.amount + selectedOffer.unitPriceSummary.excludedTaxAmount + selectedOffer.shoppingCartSummary.amount + selectedOffer.shoppingCartSummary.excludedTaxAmount) / selectedOffer.rates.length) | toCurrency
            }} {{ $t('roomSelect.pricePerNight') }}
          </div>
          <div v-else class="text-gray">
            {{ hotel.details.currency }}&nbsp;{{
              ((selectedOffer.unitPriceSummary.amount + selectedOffer.shoppingCartSummary.amount) / selectedOffer.rates.length) | toCurrency
            }} {{ $t('roomSelect.pricePerNight') }}
          </div>
          <div v-if="hotel.details.google === true" class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{
              (selectedOffer.unitPriceSummary.amount + selectedOffer.unitPriceSummary.excludedTaxAmount + selectedOffer.shoppingCartSummary.amount + selectedOffer.shoppingCartSummary.excludedTaxAmount) | toCurrency
            }}</span>
          </div>
          <div v-else class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{
              (selectedOffer.unitPriceSummary.amount + selectedOffer.shoppingCartSummary.amount) | toCurrency
            }}</span>
          </div>
        </template>
        <template v-else>
          <div v-if="offerMap[roomRequest.id][roomType.id].length == 1" class="text-gray">
            <template v-if="hotel.details.google === true">
              {{ hotel.details.currency }}&nbsp;{{
                ((lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.excludedTaxAmount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount +
                  lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.excludedTaxAmount) / lowestOfferMap[roomRequest.id][roomType.id].rates.length) | toCurrency
              }} {{ $t('roomSelect.pricePerNight') }}
            </template>
            <template v-else>
              {{ hotel.details.currency }}&nbsp;{{
                ((lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount) / lowestOfferMap[roomRequest.id][roomType.id].rates.length) | toCurrency
              }} {{ $t('roomSelect.pricePerNight') }}
            </template>
          </div>
          <div v-else class="text-gray">
            <template v-if="hotel.details.google === true">
              {{ $t('roomSelect.priceFrom') }} {{ hotel.details.currency }}&nbsp;{{
                ((lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.excludedTaxAmount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.excludedTaxAmount) / lowestOfferMap[roomRequest.id][roomType.id].rates.length) | toCurrency
              }} {{ $t('roomSelect.pricePerNight') }}
            </template>
            <template v-else>
              {{ $t('roomSelect.priceFrom') }} {{ hotel.details.currency }}&nbsp;{{
                ((lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount) / lowestOfferMap[roomRequest.id][roomType.id].rates.length) | toCurrency
              }} {{ $t('roomSelect.pricePerNight') }}
            </template>
          </div>
          <div v-if="hotel.details.google === true" class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{
              (lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.excludedTaxAmount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.excludedTaxAmount) | toCurrency
            }}</span>
          </div>
          <div v-else class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{
              (lowestOfferMap[roomRequest.id][roomType.id].unitPriceSummary.amount + lowestOfferMap[roomRequest.id][roomType.id].shoppingCartSummary.amount) | toCurrency
            }}</span>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="details pb-18 px-16">
      <div class="price flex justify-between border-t border-grayLight mt-16 pt-16 text-14 leading-16">
        <span v-if="availableRooms === 0" class="text-gray">{{ $t('roomSelect.unavailable.fullyBooked') }}</span>
        <span v-else-if="(roomRequest.numAdults + roomRequest.childAgeCategories.length) > roomType.maxOccupancy" class="text-gray">{{ $t('roomSelect.unavailable.tooManyPersons') }}</span>
        <span v-else-if="(roomRequest.numAdults + roomRequest.childAgeCategories.length) < roomType.minOccupancy" class="text-gray">{{ $t('roomSelect.unavailable.tooFewPersons') }}</span>
        <span v-else class="text-gray">{{ $t('roomSelect.unavailable.other') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, ClockIcon, InfoIcon, StarIcon } from 'vue-feather-icons';
import ButtonSquare from '../ButtonSquare/ButtonSquare';
import RoomModal from '../RoomModal/RoomModal';
import RoomAddOns from '../RoomAddOns/RoomAddOns';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { isEmpty, isNil } from 'lodash/lang';
import { find, groupBy, map } from 'lodash/collection';
import { findIndex, head, indexOf, uniq } from 'lodash/array';
import { minBy } from 'lodash/math';
import RoomSelectMixin from '@/mixins/RoomSelectMixin';

export default {
  name: 'RoomType',
  components: {
    ClockIcon,
    CheckIcon,
    InfoIcon,
    StarIcon,
    // 'room-modal': RoomModal,
    'room-add-ons': RoomAddOns,
  },

  mixins: [BookingEngineMixin, RoomSelectMixin],

  props: {
    roomType: {
      type: Object,
      default: null,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      roomRequestId: null,
    };
  },

  computed: {
    isUnavailable() {
      return this.disabled || this.availableRooms <= 0;
    },
    selectedRoomTypeId() {
      const roomTypeIds = this.$store.getters.selectedOffers.filter((it) => it.roomRequestId === this.roomRequestId).map((it) => it.roomTypeId);
      return isEmpty(roomTypeIds) ? null : roomTypeIds[0];
    },
    roomRequest() {
      return find(this.$store.getters.roomRequests, (it) => it.id === this.roomRequestId);
    },
    idx() {
      return findIndex(this.$store.getters.roomRequests, (it) => it.id === this.roomRequestId);
    },
    roomTypeIdsByRoomRequest() {
      if (isNil(this.quoteResponse)) return {};
      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = uniq(map(ret[roomRequestId], 'roomTypeId'));
      });

      return ret;
    },
    lowestOfferMap() {
      if (isNil(this.quoteResponse)) return {};
      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = groupBy(ret[roomRequestId], 'roomTypeId');
        Object.keys(ret[roomRequestId]).forEach((roomTypeId) => {
          if (this.hotel.details.google === true) ret[roomRequestId][roomTypeId] = minBy(ret[roomRequestId][roomTypeId], (offer) => offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.amount + offer.shoppingCartSummary.excludedTaxAmount);
          else ret[roomRequestId][roomTypeId] = minBy(ret[roomRequestId][roomTypeId], (offer) => offer.unitPriceSummary.amount + offer.shoppingCartSummary.amount);
        });
      });

      return ret;
    },
    selectedOffer() {
      const selectedOfferIdentifier = find(this.$store.getters.selectedOffers, (it) => it.roomRequestId === this.roomRequestId);
      if (isNil(this.quoteResponse)) return null;

      return find(this.quoteResponse.offers, (it) => it.roomRequestId === selectedOfferIdentifier.roomRequestId
					&& it.roomTypeId === selectedOfferIdentifier.roomTypeId
					&& it.ratePlanId === selectedOfferIdentifier.ratePlanId);
    },
    offerMap() {
      if (isNil(this.quoteResponse)) return {};
      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = groupBy(ret[roomRequestId], 'roomTypeId');
      });

      return ret;
    },
    availableRooms() {
      if (isNil(this.roomSelectRoomTypeAvailability)) return 0;
      const roomTypeAvailability = this.roomSelectRoomTypeAvailability.find((it) => it.roomTypeId === this.roomType.id);
      if (isNil(roomTypeAvailability)) return 0;
      return roomTypeAvailability.available || 0;
    },
  },

  watch: {
    '$route.params.roomRequestId': function () {
      this.roomRequestId = this.$route.params.roomRequestId;
    },
  },

  created: function () {
    this.roomRequestId = this.$route.params.roomRequestId;
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    openModal(roomTypeId) {
      if (this.isUnavailable) return;

      this.$router.push({
        name: 'rateselect',
        params: {
          roomRequestId: this.roomRequestId,
          roomTypeId: roomTypeId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/import';

.room-option {
	@apply grid;
	grid-template-rows: min-content auto min-content;

	.details {
		@apply cursor-pointer;
	}

	&.active {
		@apply border-3 border-primary;
	}

	&.disabled {
		.room-images, .room-image, .room-images-wrapper {
			filter: grayscale(100%);
		}

    cursor: not-allowed!important;

    * {
      cursor: not-allowed!important;
    }
	}

  &-preferred-icon {
    @apply absolute top-8 right-8 z-20;
  }
}

.room-images-wrapper {
	@apply relative h-full;
	&:before { content:''; float: left; padding-bottom: 60%;  }
	&:after  { content:''; display: table; clear: both;  }
}
// if its a single image
.room-image {
	@apply grid absolute left-0 top-0 right-0 bottom-0;
	img {
		@apply absolute left-0 top-0 w-full h-full object-cover;
	}
}
// if >= 4 images
.room-images {
	@apply grid absolute left-0 top-0 right-0 bottom-0 h-full w-full;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 0.4rem;
	.image {
		@apply relative;
	}
	img {
		@apply absolute left-0 top-0 w-full h-full object-cover;
	}
	.last {
		&:before {
			content: "";
			@apply absolute left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 block z-10;
		}
		&:after {
			content: "+"attr(data-remaining-images);
			@apply absolute top-0 left-0 flex items-center justify-center w-full h-full z-20 text-white font-semibold;
		}
	}
}

.cursor-help {
	cursor: help;
}
</style>
