<template>
  <div :key="`${roomRequestId}-${roomTypeId}`" class="z-80 select-none">
    <fade-transition>
      <div v-show="show" class="backdrop" @click="close" />
    </fade-transition>
    <div ref="modal"
         :class="[{'right-0': show}]"
         class="z-20 addon-modal fixed top-0 w-full h-screen min-h-screen bg-white z-80 overflow-y-auto overflow-x-hidden scrolling-touch shadow-modal"
    >
      <div v-if="mounted && !isNil(selectedOffer)" class="absolute top-0 left-0 right-0 bottom-0">
        <fade-transition>
          <button v-show="show" class="close-button fixed top-16 right-28 close rounded-full w-40 h-40 bg-white flex items-center justify-center z-90 shadow-md border border-grayLight" @click="close">
            <x-icon size="1.5x" class="text-dark"></x-icon>
          </button>
        </fade-transition>
        <div class="room-name">
          <h1 class="h2 p-24">
            {{ roomTypeMap[roomTypeId].name[locale] }}
          </h1>
        </div>
        <div class="package-selected flex items-center justify-between border-b border-t border-grayLight py-16 px-24">
          <div class="package-name flex ">
            <!--            <div class="icon pr-6 text-18">-->
            <!--              🎭-->
            <!--            </div>-->
            <div class="title font-semibold">
              {{ ratePlanMap[ratePlanId].name[locale] }}
            </div>
          </div>
          <button class="text-primary text-body-small py-6" @click="$router.push({ name: 'rateselect', params: { roomRequestId: roomRequestId, roomTypeId: roomTypeId } })">
            {{ $t('roomAddOns.change') }}
          </button>
        </div>
        <div class="add-ons p-24">
          <h3 class="add-ons-title text-body-small font-semibold mb-24">
            {{ $t('roomAddOns.selectAdditionalServicesHeading') }}
          </h3>
          <template v-for="itItem in selectedOffer.bookableShoppingCartItems">
            <div :key="itItem.shoppingCartItem.inHouseServiceId"
                 :class="[
                   { 'mb-24' : internalValue[itItem.shoppingCartItem.inHouseServiceId].selected },
                   { 'mb-8' : !internalValue[itItem.shoppingCartItem.inHouseServiceId].selected },
                   { 'row-span-2' : internalValue[itItem.shoppingCartItem.inHouseServiceId].selected }
                 ]"
                 class="add-on overflow-hidden"
            >
              <div class="flex justify-between">
                <div class="item">
                  <div class="checkbox block w-full">
                    <Checkbox v-model="internalValue[itItem.shoppingCartItem.inHouseServiceId].selected"
                              :name="itItem.shoppingCartItem.inHouseServiceId"
                              @input="onselectBookableInHouseService(itItem.shoppingCartItem.inHouseServiceId, $event)"
                    >
                      <span>{{ inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].name[locale] }}</span><br />
                      <span class="text-gray text-body-small">+ <span class="font-bold"> {{ hotel.details.currency }}&nbsp;{{ (max(1, internalValue[itItem.shoppingCartItem.inHouseServiceId].count) * (itItem.shoppingCartItem.unitPriceSummary.amount + itItem.shoppingCartItem.unitPriceSummary.excludedTaxAmount)) | toCurrency }}</span>
                        <template v-if="inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].rateFrequency === 'PerNight'">
                          {{ $tc('roomAddOns.forXnights', numberNights, [numberNights]) }}
                        </template>
                        <!--                        <template v-if="inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].rateMode === 'PerPerson'">-->
                        <!--                          {{ $t('summary.perPerson') }}-->
                        <!--                        </template>-->
                        <!--                        <template v-if="inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].rateFrequency === 'PerNight'">-->
                        <!--                          {{ $t('summary.perNight') }}-->
                        <!--                        </template>-->
                      </span>
                    </Checkbox>
                  </div>
                </div>
                <div class="info">
                  <button>
                    <info-icon v-if="!isEmpty(getIhsTooltip(itItem.shoppingCartItem.inHouseServiceId))"
                               v-tooltip="{ content: getIhsTooltip(itItem.shoppingCartItem.inHouseServiceId) }"
                               size="24"
                               class="text-grayBlue cursor-help"
                    ></info-icon>
                  </button>
                </div>
              </div>
              <template v-if="internalValue[itItem.shoppingCartItem.inHouseServiceId].selected">
                <div class="add-on-count pl-36">
                  <button-square decrement
                                 :disabled="internalValue[itItem.shoppingCartItem.inHouseServiceId].count <= itItem.minCount"
                                 @click="updateBookableInHouseServiceCount(itItem.shoppingCartItem.inHouseServiceId, -1)"
                  />
                  <div class="count w-44 flex items-center font-semibold w-full">
                    <span class="truncate text-center w-full"
                          :title="`${internalValue[itItem.shoppingCartItem.inHouseServiceId].count} x ${inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].name[locale]}`"
                    >{{ internalValue[itItem.shoppingCartItem.inHouseServiceId].count | toNumber }} x {{ inHouseServiceMap[itItem.shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                  </div>
                  <button-square increment
                                 :disabled="internalValue[itItem.shoppingCartItem.inHouseServiceId].count >= itItem.maxCount"
                                 @click="updateBookableInHouseServiceCount(itItem.shoppingCartItem.inHouseServiceId, 1)"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
        <button-floating-sidebar @click="onClickNext">
          {{ $tc('roomAddOns.selectXadditionalServicesButton', selectedOffer.bookableShoppingCartItems.filter(itItem => internalValue[itItem.shoppingCartItem.inHouseServiceId].count > 0).length, [selectedOffer.bookableShoppingCartItems.filter(itItem => internalValue[itItem.shoppingCartItem.inHouseServiceId].count > 0).length]) }}
        </button-floating-sidebar>
      </div>
    </div>
  </div>
</template>

<script>

import ResizeSensor from 'vue-resize-sensor';
import Checkbox from '../InputElements/Checkbox/Checkbox';
import ButtonSquare from '../ButtonSquare/ButtonSquare';
import ButtonFloating from '../ButtonFloating/ButtonFloating';
import { InfoIcon, ClockIcon, XIcon, CheckIcon } from 'vue-feather-icons';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import RoomSelectMixin from '@/mixins/RoomSelectMixin';
import { FadeTransition, SlideXLeftTransition } from 'vue2-transitions';
import TimerMixin from '@/mixins/TimerMixin';
import { isEmpty, isNil } from 'lodash/lang';
import { find, groupBy, keyBy, sortBy } from 'lodash/collection';
import ButtonFloatingSidebar from '@/components/lib/ButtonFloating/ButtonFloatingSidebar';
import { v4 as uuidv4 } from 'uuid';
import DateRangeMixin from '@/mixins/DateRangeMixin';

export default {
  name: 'RoomAddOns',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ButtonFloatingSidebar,
    'button-square': ButtonSquare,
    InfoIcon,
    ClockIcon,
    XIcon,
    CheckIcon,
    Checkbox,
    'button-floating': ButtonFloating,
    FadeTransition,
    SlideXLeftTransition,
  },

  mixins: [DateRangeMixin, BookingEngineMixin, RoomSelectMixin, TimerMixin],

  props: {
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  data: function () {
    return {
      show: false,
      mounted: false,
      roomRequestId: null,
      roomTypeId: null,
      ratePlanId: null,
      internalValue: {},
      key: uuidv4(),
    };
  },

  computed: {
    selectedOffer() {
      if (isNil(this.quoteResponse)) return null;

      return find(this.quoteResponse.offers, (it) => it.roomRequestId === this.roomRequestId
          && it.roomTypeId === this.roomTypeId
          && it.ratePlanId === this.ratePlanId);
    },
    ageCategoryMap() {
      return keyBy(this.hotel.ageCategories, 'id');
    },
    storageValue() {
      return Object.entries(this.internalValue).map((it) => ({
        inHouseServiceId: it[0],
        ...it[1],
      })).filter((it) => it.selected);
    },
    numberNights() {
      return Math.round((this.departureDateDate - this.arrivalDateDate) / (1000 * 60 * 60 * 24));
    },
  },

  watch: {
    'selectedOffer.bookableShoppingCartItems': {
      handler: function (newVal) {
        this.updateInternalValue(newVal);
      },
      deep: true,
    },
  },

  created: function () {
    this.roomRequestId = this.$route.params.roomRequestId;
    this.roomTypeId = this.$route.params.roomTypeId;
    this.ratePlanId = this.$route.params.ratePlanId;
    // this.selection = this.value == null ? [] : this.value;

    if (!isNil(this.selectedOffer)) {
      this.updateInternalValue(this.selectedOffer.bookableShoppingCartItems);
    }
  },

  mounted: function () {
    this.mounted = true;
    this.show = true;
    disableBodyScroll(this.$refs.modal);
  },

  beforeDestroy() {
    enableBodyScroll(this.$refs.modal);
  },

  methods: {
    uuidv4,
    max: Math.max,
    close() {
      if (this.show) {
        this.show = false;
        this.setTimeout(() => {
          this.$router.push({
            name: 'roomselect',
            params: {
              roomRequestId: this.roomRequestId,
            },
          });
        }, 300);
        enableBodyScroll(this.$refs.modal);
      }
    },
    updateInternalValue(newVal) {
      if (isNil(newVal)) {
        this.internalValue = {};
      } else {
        const ihsIds = newVal.map((it) => it.shoppingCartItem.inHouseServiceId);

        const storedShoppingCart = this.$store.getters.selectedShoppingCartItems.find((it) => it.roomRequestId === this.roomRequestId);

        let storedShoppingCartItems = {};
        if (!isEmpty(storedShoppingCart) && !isEmpty(storedShoppingCart.shoppingCartItems)) {
          storedShoppingCartItems = keyBy(storedShoppingCart.shoppingCartItems.map((shoppingCartItem) => ({ ...shoppingCartItem, roomRequestId: storedShoppingCart.roomRequestId })), 'inHouseServiceId');
        }

        newVal.forEach((it) => {
          const ihsId = it.shoppingCartItem.inHouseServiceId;
          const storedShoppingCartItem = storedShoppingCartItems[ihsId];

          if (!isNil(ihsId)) {
            if (isNil(this.internalValue[ihsId])) {
              this.$set(this.internalValue, ihsId, {
                selected: false,
                count: isNil(storedShoppingCartItem) ? 0 : storedShoppingCartItem.count,
              });
            } else {
              this.internalValue[ihsId].selected = isNil(storedShoppingCartItem) ? false : storedShoppingCartItem.selected;
              this.internalValue[ihsId].count = isNil(storedShoppingCartItem) ? 0 : storedShoppingCartItem.count;
              if (this.internalValue[ihsId].selected) {
                if (this.internalValue[ihsId].count > it.maxCount) this.internalValue[ihsId].count = it.maxCount;
                if (this.internalValue[ihsId].count < it.minCount) this.internalValue[ihsId].count = it.minCount;
              } else {
                this.internalValue[ihsId].count = 0;
              }
            }
          }
        });

        Object.keys(this.internalValue)
          .filter((it) => !ihsIds.includes(it))
          .forEach((it) => this.$delete(this.internalValue, it));
      }
    },
    onselectBookableInHouseService(ihsId, $event) {
      if ($event) {
        this.internalValue[ihsId].count = Math.max(this.internalValue[ihsId].count, this.selectedOffer.bookableShoppingCartItems.find((it) => it.shoppingCartItem.inHouseServiceId === ihsId).minCount);
      } else {
        this.internalValue[ihsId].count = 0;
      }
    },
    updateBookableInHouseServiceCount(ihsId, offset) {
      this.internalValue[ihsId].count += offset;
    },
    getIhsTooltip(ihsId) {
      const ihs = this.inHouseServiceMap[ihsId];
      let description = ihs.description[this.locale];

      if (ihs.rateMode === 'PerPerson' && !isEmpty(ihs.ageCategories)) {
        if (!isEmpty(description)) description += '<br/><br/>';
        description += `${this.$t('roomAddOns.forPersonsOfTheFollowingAgeCategories')}:<br/>`;
        ihs.ageCategories.forEach((it) => {
          const ac = this.ageCategoryMap[it];
          if (!isNil(ac)) {
            description += `•&nbsp;${ac.name[this.locale]}<br/>`;
          }
        });
      }

      return description;
    },
    onClickNext() {
      this.$store.dispatch('updateShoppingCartItems', { roomRequestId: this.roomRequestId, shoppingCartItems: this.storageValue });

      const router = this.$router;
      const selectedOffers = this.$store.getters.selectedOffers;
      const roomRequests = this.$store.getters.roomRequests;
      const roomRequestId = this.roomRequestId;

      this.executeTimeline([
        () => this.close(),
        300,
        () => window.scroll({ top: 0, behavior: 'smooth' }),
        300,
        () => {
          const completedRoomRequestIds = selectedOffers
            .filter((it) => !isNil(it.roomRequestId) && !isNil(it.roomTypeId) && !isNil(it.ratePlanId))
            .map((it) => it.roomRequestId);
          const openRoomRequestIds = roomRequests
            .map((it) => it.id)
            .filter((it) => !completedRoomRequestIds.includes(it));

          if (openRoomRequestIds.length > 0) {
            router.push({
              name: 'roomselect',
              params: { roomRequestId: openRoomRequestIds[0] },
            });
          } else {
            const contactData = this.$store.getters.contactData;
            if (isNil(contactData) || contactData.complete !== true) {
              this.$router.push({ name: 'contact' });
            } else {
              router.push({ name: 'summary' });
            }
          }
        },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/import';

  .addon-modal {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;

    transition: 0.3s right;

    right: -700px;
    @apply max-w-700;

    &.right-0 {
      right: 0px;
    }

    .close-button {
      right: 2.4rem;
      top: 1.6rem;

      @media (min-width: 788px) {
        right: calc(700px + 2.4rem);
        top: 2.4rem;
      }
    }
  }

  .button {
    @apply bg-primary py-12 px-24 text-white rounded-32 inline-block font-medium;
    &:disabled {
      @apply bg-grayBlue cursor-not-allowed;
    }
  }

  .room-name {
    max-width: 80%;
  }

  .add-ons {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 3rem;

    @media (min-width: 768px) {
      /* minmax(0, 1fr) enforces equal column width - see: https://stackoverflow.com/questions/47601564/equal-width-columns-in-css-grid */
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      &-title {
        @apply col-span-2;
      }
    }
  }

  .add-on-count {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 4rem calc(100% - 10rem) 4rem;
    grid-column-gap: 1rem;
  }

</style>
