// define a mixin object
import { isEmpty, isFunction, isNil, isNumber } from 'lodash/lang';
import { replace, endsWith, trimEnd } from 'lodash/string';
import ServiceUrls from '@/lib/ServiceUrls';
import { keyBy } from 'lodash/collection';

import { throttle } from 'lodash/function';
import baseUrl from '@/lib/urls';
import { head } from 'lodash/array';

export default {

  computed: {
    remainingRoomTypeAvailability() {
      if (isNil(this.roomTypeAvailability)) return null;
      return this.roomTypeAvailability
        .map((rta) => ({
          ...rta,
          available: rta.available - this.selectedRoomTypeMap
            .filter((it) => it.roomTypeId === rta.roomTypeId)
            .length,
        }));
    },
    roomSelectRoomTypeAvailability() {
      if (isNil(this.roomTypeAvailability)) return null;
      return this.roomTypeAvailability
        .map((rta) => ({
          ...rta,
          available: rta.available - this.selectedRoomTypeMap
            .filter((it) => it.roomRequestId !== this.roomRequestId)
            .filter((it) => it.roomTypeId === rta.roomTypeId)
            .length,
        }));
    },
    selectedRoomTypeMap() {
      return this.roomRequests.map((it) => ({
        roomRequestId: it.id,
        roomTypeId: head(this.$store.getters.selectedOffers.filter((offer) => offer.roomRequestId === it.id).map((offer) => offer.roomTypeId)),
      }));
    },
  },
};
