<template>
  <column1>
    <room-select></room-select>
  </column1>
</template>

<script>
import AvailabilityCalendar from '@/components/lib/calendar/AvailabilityCalendar';
import Nav from '@/components/lib/Nav/MainNav';
import InfoBox from '@/components/lib/InfoBox/InfoBox';
import RoomSelect from '@/components/lib/RoomSelect/RoomSelect';
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';

export default {
  name: 'RoomSelectView',
  components: {
    Column1,
    'room-select': RoomSelect,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
