<template>
  <room-add-ons />
</template>

<script>
import RoomAddOns from '@/components/lib/RoomAddOns/RoomAddOns';

export default {
  name: 'RoomSelectView',
  components: {
    RoomAddOns,
  },
  props: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
