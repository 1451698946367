<template>
  <div :key="`${roomRequestId}-${roomTypeId}`" class="z-80 select-none">
    <fade-transition>
      <div v-show="show" class="backdrop" @click="close" />
    </fade-transition>
    <div ref="modal"
         :class="[{'right-0': show}, {'room-modal--large': settings.roomSelectionDisplay === 'Wide' && !isEmpty(roomTypeMap[roomTypeId].originalImages)}]"
         class="z-20 room-modal fixed top-0 w-full h-screen min-h-screen bg-white z-80 overflow-y-scroll overflow-x-hidden scrolling-touch shadow-modal"
    >
      <fade-transition>
        <button v-show="show" class="close-button fixed top-16 right-28 close rounded-full w-40 h-40 bg-white flex items-center justify-center z-90 shadow-md border border-grayLight" @click="close">
          <x-icon size="1.0x" class="text-dark"></x-icon>
        </button>
      </fade-transition>

      <!--      <div class="grid w-100 bg-light col-span-3 border-b border-grayLight py-12 w-full align-center justify-center">-->
      <!--        <button v-if="selectedRoomTypeId === roomTypeId" class="button" @click="selectOffer(offerMap[roomRequestId][roomTypeId][0].ratePlanId)">-->
      <!--          <span class="grid grid-cols-selectedOffer">-->
      <!--            <span class="pr-12">Gewählt</span><check-icon size="1.4x" class="text-white"></check-icon>-->
      <!--          </span>-->
      <!--        </button>-->
      <!--        <button v-else class="button" @click="selectOffer(offerMap[roomRequestId][roomTypeId][0].ratePlanId)">-->
      <!--          Jetzt buchen-->
      <!--        </button>-->
      <!--      </div>-->

      <div v-if="!isEmpty(roomTypeMap[roomTypeId].originalImages)" class="swiper">
        <div class="swiper-container gallery-top relative">
          <div v-show="roomTypeMap[roomTypeId].originalImages.length > 1" class="btn-gallery-next absolute top-0 h-full w-200 left-0 z-90">
            <button class="bg-light absolute left-24 top-1/2 z-80 close rounded-full w-32 h-32 bg-white flex items-center justify-center shadow-md" @click="swipeLeft">
              <chevron-left-icon size="24" class="text-dark" />
            </button>
          </div>

          <div class="swiper-wrapper">
            <div v-for="(image, idx) in roomTypeMap[roomTypeId].originalImages"
                 class="swiper-slide"
            >
              <img :key="idx"
                   :src="roomTypeMap[roomTypeId].thumbImages[idx]"
                   :srcset="`${roomTypeMap[roomTypeId].thumbImages[idx]} 800w, ${roomTypeMap[roomTypeId].images[idx]} 1920w, ${image} 2560w`"
                   :alt="roomTypeMap[roomTypeId].name[locale]"
                   sizes="(min-width: 1600px) 60vw, 700px"
              />
            </div>
          <!--                 :style="{backgroundImage: `url('${image}')`}"-->
          </div>

          <div v-show="roomTypeMap[roomTypeId].originalImages.length > 1" class="btn-gallery-next absolute top-0 h-full w-200 right-0 z-90">
            <button class="bg-light absolute right-24 top-1/2 z-80 close rounded-full w-32 h-32 bg-white flex items-center justify-center shadow-md" @click="swipeRight">
              <chevron-right-icon size="24" class="text-dark" />
            </button>
          </div>
        </div>
        <div class="swiper-container gallery-thumbs relative">
          <div v-show="galleryThumbsProgress > 0 && galleryThumbsVisibleSlides !== roomTypeMap[roomTypeId].originalImages.length" class="btn-thumbs-prev absolute top-0 h-full w-200 left-0 z-90 max-w-1/4">
            <button class="bg-dark absolute left-16 top-1/2 z-80 close rounded-full w-24 h-24 bg-white flex items-center justify-center shadow-md" @click="onClickPrevThumb">
              <chevron-left-icon size="1x" class="text-white" />
            </button>
          </div>
          <div class="swiper-wrapper">
            <div v-for="image in roomTypeMap[roomTypeId].thumbImages" class="swiper-slide" :style="{backgroundImage: `url('${image}')`}"></div>
          </div>
          <div v-show="galleryThumbsProgress < 100 && galleryThumbsVisibleSlides !== roomTypeMap[roomTypeId].originalImages.length" class="btn-thumbs-next absolute top-0 h-full w-200 right-0 z-90">
            <button class="bg-dark absolute right-16 top-1/2 z-80 close rounded-full w-24 h-24 bg-white flex items-center justify-center shadow-md" @click="onClickNextThumb">
              <chevron-right-icon size="1x" class="text-white" />
            </button>
          </div>
        </div>
      </div>

      <div class="details row-span-2 p-24">
        <h1 class="h2 mb-8">
          {{ roomTypeMap[roomTypeId].name[locale] }}
        </h1>
        <div class="specs text-gray text-14 leading-16 mt-8">
          {{ $tc('roomSelect.occupancy', roomTypeMap[roomTypeId].maxOccupancy, [roomTypeMap[roomTypeId].minOccupancy, roomTypeMap[roomTypeId].maxOccupancy]) }}
        </div>
        <div v-if="!isEmpty(offerMap[roomRequestId][roomTypeId]) && availableRooms <= settings.lowAvailabilityMessageThreshold" class="hurry flex items-center text-error text-body-small mt-9">
          <clock-icon size="14" class="text-warn mr-5"></clock-icon>
          <span>{{ $tc('roomSelect.onlyXroomAvailable', availableRooms, [availableRooms]) }}</span>
        </div>
        <div v-if="!isEmpty(offerMap[roomRequestId][roomTypeId])" class="price flex justify-between border-t border-b border-grayLight mt-16 py-16 text-14 leading-16">
          <div v-if="hotel.details.google === true" class="text-gray">
            {{ $t('roomSelect.priceFrom') }} {{ hotel.details.currency }}&nbsp;{{ ((lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.excludedTaxAmount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.excludedTaxAmount) / lowestOfferMap[roomRequestId][roomTypeId].rates.length) | toCurrency }} {{ $t('roomSelect.pricePerNight') }}
          </div>
          <div v-else class="text-gray">
            {{ $t('roomSelect.priceFrom') }} {{ hotel.details.currency }}&nbsp;{{ ((lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.amount) / lowestOfferMap[roomRequestId][roomTypeId].rates.length) | toCurrency }} {{ $t('roomSelect.pricePerNight') }}
          </div>
          <div v-if="hotel.details.google === true" class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{ (lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.excludedTaxAmount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.excludedTaxAmount) | toCurrency }}</span>
          </div>
          <div v-else class="text-dark font-medium">
            {{ $t('roomSelect.priceTotal') }} <span class="font-bold">{{ hotel.details.currency }}&nbsp;{{ (lowestOfferMap[roomRequestId][roomTypeId].unitPriceSummary.amount + lowestOfferMap[roomRequestId][roomTypeId].shoppingCartSummary.amount) | toCurrency }}</span>
          </div>
        </div>
        <!--
        <div class="features mt-24 pb-24 border-b border-grayLight">
          <h2 class="text-body-small font-semibold mb-16">
            Ausstattung
          </h2>
          <div class="feature-list">
            <div class="feature">
              <div class="icon">
                <clock-icon size="20" class="text-gray mr-5"></clock-icon>
              </div>
              <div class="description text-caption">
                Queensize Bett<br />
                (200 × 200)
              </div>
            </div>
            <div class="feature">
              <div class="icon">
                <clock-icon size="20" class="text-gray mr-5"></clock-icon>
              </div>
              <div class="description text-caption">
                Bad mit Dusche
              </div>
            </div>
            <div class="feature">
              <div class="icon">
                <clock-icon size="20" class="text-gray mr-5"></clock-icon>
              </div>
              <div class="description text-caption">
                Fernseher
              </div>
            </div>
            <div class="feature">
              <div class="icon">
                <clock-icon size="20" class="text-gray mr-5"></clock-icon>
              </div>
              <div class="description text-caption">
                Wi-Fi
              </div>
            </div>
          </div>
        </div>
        -->
        <div v-if="!isEmpty(roomTypeMap[roomTypeId].description[locale])" class="description mt-24 pb-24 border-b border-grayLight">
          <h2 class="text-body-small font-semibold mb-16">
            {{ $t('roomSelect.roomTypeDescription') }}
          </h2>
          <div ref="description" class="description-copy text-body" :class="{'truncated' : truncated}">
            <div ref="descriptionWrapper" class="description-copy--wrapper">
              <p class="whitespace-pre-line">
                {{ roomTypeMap[roomTypeId].description[locale] }}
              </p>
            </div>
          </div>
          <button v-if="showTruncateButton" class="read-more text-primary text-body-small font-medium w-full text-center block py-3 focus:outline-none" aria-label="Read more" @click="truncated = !truncated">
            <span v-if="truncated">{{ $t('roomSelect.showMore') }}</span>
            <span v-if="!truncated">{{ $t('roomSelect.showLess') }}</span>
          </button>
        </div>

        <div class="packages mb-32">
          <div v-for="offer in offerMap[roomRequestId][roomTypeId]" class="package border-1.5 border-grayLight rounded-8 p-16">
            <div class="package-name flex items-center border-b border-grayLight pb-16">
              <!--
              <div class="icon pr-6 text-18">
                🎭
              </div>
              -->
              <div class="title font-semibold">
                {{ ratePlanMap[offer.ratePlanId].name[locale] }}
              </div>
              <div v-if="offer.ratePlanId === preferredRatePlanId || 1 === 1" class="package-preferred-icon">
                <star-icon size="1.5x" stroke-width="2" stroke="var(--color-primary)" fill="transparent"></star-icon>
              </div>
              <!--              <div class="bg-error text-white absolute -top-8 text-10 py-4 px-4 rounded-4 font-medium">-->
              <!--                {{ $t('roomCountView.discountCode') }}:&nbsp;{{ discountCode }}-->
              <!--              </div>-->
            </div>
            <div class="package-features">
              <div class="mt-16 mb-16 text-14 leading-loose">
                <p v-if="dompurify(ratePlanMap[offer.ratePlanId].longDescription[locale]).length < 20" class="whitespace-pre-line" v-html="dompurify(ratePlanMap[offer.ratePlanId].description[locale])"></p>
                <div class="package-features--long" v-html="dompurify(ratePlanMap[offer.ratePlanId].longDescription[locale])" />
                <div class="package-features--long">
                  <ul>
                    <li v-if="!isEmpty( ((ratePlanMap[offer.ratePlanId].includedMealPlan || {}).name || {})[locale])"
                        class="flex justify-items-start"
                    >
                      {{ ((ratePlanMap[offer.ratePlanId].includedMealPlan || {}).name || {})[locale] }} inbegriffen
                    </li>
                    <!--                  <div class="bg-error text-white absolute -top-8 text-10 py-4 px-4 rounded-4 font-medium">-->
                    <!--                    {{ $t('roomCountView.discountCode') }}:&nbsp;{{ discountCode }}-->
                    <!--                  </div>-->
                    <template v-if="isNil(offer.policies)">
                      <li v-if="ratePlanMap[offer.ratePlanId].webPrepayment === 0 && hotel.details.onlineBookingSettings.hideNoPrepayment !== true">
<!--
                        -->{{ $t('roomSelect.noDepositAtTimeOfBooking') }}
                      </li>
                      <li v-if="ratePlanMap[offer.ratePlanId].webPrepayment > 0">
<!--
                        -->{{ $t('roomSelect.percentDepositAtTimeOfBooking', [$options.filters.toNumber(ratePlanMap[offer.ratePlanId].webPrepayment)]) }}
                      </li>
                    </template>
                    <template v-else>
                      <li v-if="!isNil(offer.policies.cancellationPolicy)" class="flex justify-items-start">
                        <span v-if="offer.policies.cancellationPolicy.penaltyAfterReservation === 0 && offer.policies.cancellationPolicy.deadlineDays <= 1"><!--
                          -->{{ $t('roomSelect.policies.freeCancellation') }}
                        </span>
                        <span v-else-if="offer.policies.cancellationPolicy.penaltyAfterReservation === 0 && offer.policies.cancellationPolicy.penaltyAfterDeadline === 0"><!--
                          -->{{ $t('roomSelect.policies.freeCancellation') }}
                        </span>
                        <span v-else-if="offer.policies.quotePolicyDetails.freeCancellation"><!--
                          -->{{ $t('roomSelect.policies.flexCancellation') }}
                        </span>
                        <span v-else>{{ $t('roomSelect.policies.noFreeCancellation') }}</span>
                        <div class="info ml-8">
                          <button>
                            <info-icon v-tooltip="{ content: getCancellationPolicyTooltip(offer.policies) }"
                                       size="24"
                                       class="text-grayBlue cursor-help"
                            ></info-icon>
                          </button>
                        </div>
                      </li>
                      <li v-if="!isNil(offer.policies.prepaymentPolicy)" class="flex justify-items-start">
                        <span v-if="offer.policies.prepaymentPolicy.penaltyAfterReservation === 0 && offer.policies.prepaymentPolicy.penaltyAfterDeadline === 0"><!--
                          -->{{ $t('roomSelect.policies.noDeposit') }}
                        </span>
                        <span v-else-if="offer.policies.prepaymentPolicy.penaltyAfterReservation === 100 && offer.policies.prepaymentPolicy.penaltyType === 'Percentage'"><!--
                          -->{{ $t('roomSelect.policies.fullPrepayment') }}
                        </span>
                        <span v-else>{{ $t('roomSelect.policies.depositRequired') }}</span>
                        <div class="info ml-8">
                          <button>
                            <info-icon v-tooltip="{ content: getPrepaymentPolicyTooltip(offer.policies) }"
                                       size="24"
                                       class="text-grayBlue cursor-help"
                            ></info-icon>
                          </button>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <!--            <template v-if="!isNil(offer.policies)">-->
            <!--              <div class="package-policies border-t border-grayLight flex justify-between items-center pt-16">-->
            <!--                <div class="mb-16 leading-loose">-->
            <!--                  <p>-->
            <!--                    <b>Buchungsbedingungen</b>-->
            <!--                  </p>-->
            <!--                  <p v-if="!isNil(offer.policies.cancellationPolicy)">-->
            <!--                    {{ offer.policies.quotePolicyDetails.cancellationPolicy[locale] }}-->
            <!--                  </p>-->
            <!--                  <p v-if="!isNil(offer.policies.prepaymentPolicy)">-->
            <!--                    {{ offer.policies.quotePolicyDetails.prepaymentPolicy[locale] }}-->
            <!--                  </p>-->
            <!--                  <p v-if="!isNil(offer.policies.noShowPolicy)">-->
            <!--                    {{ offer.policies.quotePolicyDetails.noShowPolicy[locale] }}-->
            <!--                  </p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </template>-->
            <div class="package-cta border-t border-grayLight flex justify-between items-center pt-16" :class="{ 'pb-16': 1===1 }">
              <div class="package-total flex flex-col">
                <div class="text-gray text-caption">
                  {{ $t('roomSelect.priceTotal') }}
                </div>
                <div v-if="hotel.details.google === true" class="text-primary font-semibold">
                  {{ hotel.details.currency }}&nbsp;{{ (offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.amount + offer.shoppingCartSummary.excludedTaxAmount) | toCurrency }}
                </div>
                <div v-else class="text-primary font-semibold">
                  {{ hotel.details.currency }}&nbsp;{{ (offer.unitPriceSummary.amount + offer.shoppingCartSummary.amount) | toCurrency }}
                </div>
                <div v-if="(offer.unitPriceSummary.amount + offer.shoppingCartSummary.amount) !== (offer.unitPriceSummary.originalAmount + offer.shoppingCartSummary.originalAmount)"
                     class="font-semibold text-12 line-through text-gray font-medium original-price"
                >
                  <template v-if="hotel.details.google === true">
                    {{ hotel.details.currency }}&nbsp;{{ (offer.unitPriceSummary.originalAmount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.originalAmount + offer.shoppingCartSummary.excludedTaxAmount) | toCurrency }}
                  </template>
                  <template v-else>
                    {{ hotel.details.currency }}&nbsp;{{ (offer.unitPriceSummary.originalAmount + offer.shoppingCartSummary.originalAmount) | toCurrency }}
                  </template>
                </div>
              </div>
              <button v-if="offer.ratePlanId === selectedRatePlanId" class="button" @click="selectOffer(offer.ratePlanId)">
                <span class="grid grid-cols-selectedOffer">
                  <span class="pr-12">{{ $t('roomSelect.selected') }}</span><check-icon size="1.4x" class="text-white"></check-icon>
                </span>
              </button>
              <button v-else class="button" @click="selectOffer(offer.ratePlanId)">
                {{ $t('roomSelect.actionSelect') }}
              </button>
            </div>
            <div v-if="offer.ratePlanId === selectedRatePlanId && displayShoppingCart" class="addons border-t border-grayLight justify-between items-center pt-16">
              <div class="title flex justify-between mb-8">
                <span class="font-semibold text-14 text-dark">Ausgewählte Zusatzleistungen</span>
                <button>
                  <span class="text-14 text-primary font-medium text-right block"
                        @click="$router.push({
                          name: 'roomaddons',
                          params: {
                            roomRequestId: roomRequestId,
                            roomTypeId: roomTypeId,
                            ratePlanId: offer.ratePlanId,
                          },
                        })"
                  >{{ $t('summary.change') }}</span>
                </button>
              </div>
              <div v-if="!isEmpty(roomSelectedShoppingCartItems)" class="addons__list">
                <ul>
                  <template v-for="shoppingCartItem in roomSelectedShoppingCartItems">
                    <li v-if="shoppingCartItem.selected === true && shoppingCartItem.count > 0">
                      <span>{{ shoppingCartItem.count }} x {{ inHouseServiceMap[shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                      <div class="info">
                        <button>
                          <info-icon v-if="!isEmpty(inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale])"
                                     v-tooltip="{ content: inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale] }"
                                     size="24"
                                     class="text-grayBlue cursor-help"
                          ></info-icon>
                        </button>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>

        <!--
          <div class="package disabled border border-grayLight rounded-8 p-16 mt-24">
            <div class="package-name flex items-center border-b border-grayLight pb-16">
              <div class="title font-semibold">
                Tradition & Kultur
              </div>
            </div>
            <div class="package-features">
              <ul class="mt-16">
                <li class="flex justify-between mb-16">
                  <div class="package-feature flex">
                    <div class="package-check-icon">
                      <check-icon size="18" class="text-primary mr-12"></check-icon>
                    </div>
                    <span class="text-body-small">1 × gratis Museums- oder Konzerteintritt</span>
                  </div>
                  <div class="package-feature-info inline-flex min-w-40 justify-end items-start">
                    <button v-tooltip="{ content: 'You have 10 new messages.' }">
                      <info-icon size="20" class="text-grayBlue"></info-icon>
                    </button>
                  </div>
                </li>
                <li class="flex justify-between mb-16">
                  <div class="package-feature flex">
                    <div class="package-check-icon">
                      <check-icon size="18" class="text-primary mr-12"></check-icon>
                    </div>
                    <span class="text-body-small">Führung durch die Altstadt</span>
                  </div>
                  <div class="package-feature-info inline-flex min-w-40 justify-end items-start">
                    <button>
                      <info-icon size="20" class="text-grayBlue"></info-icon>
                    </button>
                  </div>
                </li>
                <li class="flex justify-between mb-16">
                  <div class="package-feature flex">
                    <div class="package-check-icon">
                      <check-icon size="18" class="text-primary mr-12"></check-icon>
                    </div>
                    <span class="text-body-small">Reichhaltiges Frühstücksbuffet mit Müsli, Orangensaft, Pikantem und Süßem</span>
                  </div>
                  <div class="package-feature-info inline-flex min-w-40 justify-end items-start">
                    <button>
                      <info-icon size="20" class="text-grayBlue"></info-icon>
                    </button>
                  </div>
                </li>
                <li class="flex justify-between mb-16">
                  <div class="package-feature flex">
                    <div class="package-check-icon">
                      <check-icon size="18" class="text-primary mr-12"></check-icon>
                    </div>
                    <span class="text-body-small">Late-Checkout mit Voranmeldung möglich</span>
                  </div>
                  <div class="package-feature-info inline-flex min-w-40 justify-end items-start">
                  </div>
                </li>
              </ul>
            </div>
            <div class="package-cta border-t border-grayLight flex justify-between items-center pt-16">
              <div class="package-total flex flex-col">
                <div class="text-gray text-caption">
                  insgesamt
                </div>
                <div class="text-primary font-semibold">
                  {{ hotel.details.currency }}&nbsp;602,-
                </div>
              </div>
              <button class="button" disabled>
                Nicht verfügbar
              </button>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Swiper, { Thumbs, Navigation, Pagination } from 'swiper';
import ResizeSensor from 'vue-resize-sensor';
import { InfoIcon, ClockIcon, XIcon, CheckIcon, ChevronRightIcon, ChevronLeftIcon, StarIcon } from 'vue-feather-icons';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';
import BookingEngineMixin from '../../../mixins/BookingEngineMixin';
import { isEmpty, isEqual, isNil } from 'lodash/lang';
import { find, groupBy, keyBy, map, sortBy } from 'lodash/collection';
import { minBy } from 'lodash/math';
import { head, uniq } from 'lodash/array';
import vClickOutside from 'v-click-outside';
import { FadeTransition, SlideXLeftTransition } from 'vue2-transitions';
import TimerMixin from '@/mixins/TimerMixin';
import DOMPurify from 'dompurify';
import RoomSelectMixin from '@/mixins/RoomSelectMixin';

export default {
  name: 'RoomModal',
  components: { InfoIcon, ClockIcon, XIcon, CheckIcon, FadeTransition, SlideXLeftTransition, ChevronRightIcon, ChevronLeftIcon, StarIcon },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [BookingEngineMixin, TimerMixin, RoomSelectMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      show: false,
      truncated: true,
      showTruncateButton: true,
      roomRequestId: null,
      roomTypeId: null,
      galleryThumbs: null,
      galleryThumbsProgress: 0,
      galleryThumbsVisibleSlides: 0,
      galleryTop: null,
      galleryTopProgress: 0,
    };
  },

  computed: {
    isUnavailable() {
      return this.disabled || this.availableRooms <= 0;
    },
    selectedOffer() {
      return head(this.$store.getters.selectedOffers.filter((offer) => offer.roomRequestId === this.roomRequestId && offer.roomTypeId === this.roomTypeId));
    },
    selectedQuoteOffer() {
      if (isNil(this.quoteResponse)) return null;
      const selectedOfferIdentifier = this.selectedOffer;
      if (isNil(selectedOfferIdentifier)) return null;
      return find(
        this.quoteResponse.offers,
        (it) => it.ratePlanId === selectedOfferIdentifier.ratePlanId
              && it.roomTypeId === selectedOfferIdentifier.roomTypeId
              && it.roomRequestId === selectedOfferIdentifier.roomRequestId,
      );
    },
    selectedRatePlanId() {
      return isNil(this.selectedOffer) ? null : this.selectedOffer.ratePlanId;
    },
    selectedRoomTypeId() {
      return isNil(this.selectedOffer) ? null : this.selectedOffer.roomTypeId;
    },
    lowestOfferMap() {
      if (isNil(this.quoteResponse)) return {};
      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = groupBy(ret[roomRequestId], 'roomTypeId');
        Object.keys(ret[roomRequestId]).forEach((roomTypeId) => {
          if (this.hotel.details.google === true) ret[roomRequestId][roomTypeId] = minBy(ret[roomRequestId][roomTypeId], (offer) => offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.amount + offer.shoppingCartSummary.excludedTaxAmount);
          else ret[roomRequestId][roomTypeId] = minBy(ret[roomRequestId][roomTypeId], (offer) => offer.unitPriceSummary.amount + offer.shoppingCartSummary.amount);
        });
      });

      return ret;
    },
    offerMap() {
      if (isNil(this.quoteResponse)) return {};

      const sortedRatePlanIds = sortBy(this.hotel.ratePlans, ['sort', (it) => (isNil(it.name) ? null : it.name[this.locale])]).map((it) => it.id);

      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = groupBy(ret[roomRequestId], 'roomTypeId');
        Object.keys(ret[roomRequestId]).forEach((roomTypeId) => {
          ret[roomRequestId][roomTypeId] = sortBy(
            ret[roomRequestId][roomTypeId],
            [
              (it) => it.ratePlanId === this.preferredRatePlanId ? 0 : 100,
              (it) => sortedRatePlanIds.indexOf(it.ratePlanId),
            ],
          );
        });
      });

      return ret;
    },
    availableRooms() {
      if (isNil(this.roomSelectRoomTypeAvailability)) return 0;
      const roomTypeAvailability = this.roomSelectRoomTypeAvailability.find((it) => it.roomTypeId === this.roomTypeId);
      if (isNil(roomTypeAvailability)) return 0;
      return roomTypeAvailability.available || 0;
    },
    roomSelectedShoppingCartItems() {
      const storedValue = this.$store.getters.selectedShoppingCartItems;

      if (!isEmpty(storedValue)) {
        const shoppingCartWrapper = storedValue.find((it) => it.roomRequestId === this.roomRequestId);
        if (!isEmpty(shoppingCartWrapper)) {
          return isEmpty(shoppingCartWrapper.shoppingCartItems) ? [] : shoppingCartWrapper.shoppingCartItems;
        }
      }
      return [];
    },
    displayShoppingCart() {
      const shoppingCartWrapper = this.selectedShoppingCartItems.find((it) => it.roomRequestId === this.roomRequestId);

      if (isEmpty(shoppingCartWrapper) || isEmpty(shoppingCartWrapper.shoppingCartItems)) return false;

      return true;
    },
  },

  watch: {
    '$route.params.roomRequestId': function () {
      this.roomRequestId = this.$route.params.roomRequestId;
    },
    '$route.params.roomTypeId': function () {
      this.roomTypeId = this.$route.params.roomTypeId;
    },
  },

  created: function () {
    // this.$serviceBus.$on('shortcut.esc', () => this.close());
    this.$serviceBus.$on('shortcut.esc', this.close);
    this.$serviceBus.$on('shortcut.left', this.swipeLeft);
    this.$serviceBus.$on('shortcut.right', this.swipeRight);
    this.show = false;
    this.roomTypeId = this.$route.params.roomTypeId;
    this.roomRequestId = this.$route.params.roomRequestId;
  },

  beforeDestroy() {
    this.$serviceBus.$off('shortcut.esc', this.close);
    this.$serviceBus.$off('shortcut.left', this.swipeLeft);
    this.$serviceBus.$off('shortcut.right', this.swipeRight);
    enableBodyScroll(this.$refs.modal);
  },

  mounted: function () {
    // this.setTimeout(() => {
    //   this.show = true;
    // }, 5000);
    this.mounted = true;
    this.show = true;

    disableBodyScroll(this.$refs.modal);

    const $vm = this;

    Swiper.use([Thumbs]);
    this.galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 8,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 4,
        },
        640: {
          slidesPerView: 5,
        },
        1360: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1920: {
          slidesPerView: 5,
          spaceBetween: 16,
        },
      },
      nextEl: '.btn-thumbs-next',
      on: {
        update: function (swiper) {
          $vm.galleryThumbsVisibleSlides = isEmpty(swiper.visibleSlides) ? 0 : swiper.visibleSlides.length;
        },
        resize: function (swiper) {
          $vm.galleryThumbsVisibleSlides = isEmpty(swiper.visibleSlides) ? 0 : swiper.visibleSlides.length;
        },
        orientationchange: function (swiper) {
          $vm.galleryThumbsVisibleSlides = isEmpty(swiper.visibleSlides) ? 0 : swiper.visibleSlides.length;
        },
        init: function (swiper) {
          $vm.galleryThumbsVisibleSlides = isEmpty(swiper.visibleSlides) ? 0 : swiper.visibleSlides.length;
        },
        progress: function (swiper, progress) {
          $vm.galleryThumbsProgress = Math.round(progress * 100);
          $vm.galleryThumbsVisibleSlides = isEmpty(swiper.visibleSlides) ? 0 : swiper.visibleSlides.length;
        },
      },
    });
    this.galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 0,
      loop: true,
      thumbs: {
        swiper: this.galleryThumbs,
      },
    });

    this.track();

    try {
      if (this.truncated && this.$refs.descriptionWrapper.clientHeight < this.$refs.description.clientHeight) {
        this.truncated = false;
        this.showTruncateButton = false;
      }
    } catch (e) { /* ignore */ }
  },

  methods: {
    dompurify(s) {
      return DOMPurify.sanitize(s);
    },
    close() {
      if (this.show) {
        this.show = false;
        this.setTimeout(() => {
          this.$router.push({
            name: 'roomselect',
            params: {
              roomRequestId: this.roomRequestId,
            },
          });
        }, 300);
        enableBodyScroll(this.$refs.modal);
      }
    },
    swipeLeft() {
      this.galleryTop.slidePrev();
    },
    swipeRight() {
      this.galleryTop.slideNext();
    },
    selectOffer(ratePlanId) {
      const selectedOffer = {
        roomRequestId: this.roomRequestId,
        roomTypeId: this.roomTypeId,
        ratePlanId: ratePlanId,
      };

      if (isEqual(this.selectedOffer, selectedOffer)) {
        this.trackRemoveFromCart();
        this.$store.dispatch('unselectOffer', this.roomRequestId);
        return;
      }

      this.$store.dispatch('selectOffer', selectedOffer);
      this.$nextTick(this.trackAddToCart);

      const selectedOffers = this.$store.getters.selectedOffers;
      const roomRequests = this.$store.getters.roomRequests;
      const roomRequestId = this.roomRequestId;
      const router = this.$router;

      const offer = this.offerMap[roomRequestId][this.roomTypeId].find((it) => it.ratePlanId === ratePlanId);

      this.executeTimeline([
        () => this.close(),
        300,
        () => window.scroll({ top: 0, behavior: 'smooth' }),
        300,
        () => {
          if (!isEmpty(offer) && !isEmpty(offer.bookableShoppingCartItems)) {
            router.push({
              name: 'roomaddons',
              params: {
                roomRequestId: roomRequestId,
                roomTypeId: this.roomTypeId,
                ratePlanId: ratePlanId,
              },
            });
          } else {
            const completedRoomRequestIds = selectedOffers
              .filter((it) => !isNil(it.roomRequestId) && !isNil(it.roomTypeId) && !isNil(it.ratePlanId))
              .map((it) => it.roomRequestId);
            const openRoomRequestIds = roomRequests
              .map((it) => it.id)
              .filter((it) => !completedRoomRequestIds.includes(it));

            if (openRoomRequestIds.length > 0) {
              router.push({
                name: 'roomselect',
                params: { roomRequestId: openRoomRequestIds[0] },
              });
            } else {
              const contactData = this.$store.getters.contactData;
              if (isNil(contactData) || contactData.complete !== true) {
                this.$router.push({ name: 'contact' });
              } else {
                router.push({ name: 'summary' });
              }
            }
          }
        },
      ]);
    },
    onClickPrevThumb() {
      if (!isNil(this.galleryThumbs)) {
        this.galleryThumbs.slidePrev();
      }
    },
    onClickNextThumb() {
      if (!isNil(this.galleryThumbs)) {
        this.galleryThumbs.slideNext();
      }
    },
    track() {
      try {
        if (this.trackingEnabled) {
          this.$gtag.query('event', 'view_item', {
            'items': [this.roomTypeId].map((roomTypeId) => {
              const roomType = this.roomTypeMap[roomTypeId];
              return {
                'id': roomTypeId,
                'name': roomType.name[this.hotel.details.primaryLanguage],
                'category': 'RoomType',
              };
            }),
          });
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
      try {
        if (this.trackingEnabled) {
          this.$gtag.query('event', 'view_item_list', {
            'items': this.offerMap[this.roomRequestId][this.roomTypeId].map((offer) => {
              const roomTypeId = offer.roomTypeId;
              const ratePlanId = offer.ratePlanId;
              const roomType = this.roomTypeMap[roomTypeId];
              const ratePlan = this.ratePlanMap[ratePlanId];
              return {
                'id': `${roomTypeId}/${ratePlanId}`,
                'name': `${roomType.name[this.hotel.details.primaryLanguage]} / ${ratePlan.name[this.hotel.details.primaryLanguage]}`,
                'list_position': this.offerMap[this.roomRequestId][this.roomTypeId].indexOf(offer),
                'category': 'Product',
              };
            }),
          });
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    trackAddToCart() {
      try {
        if (this.trackingEnabled) {
          const selectedOffer = this.selectedOffer;
          if (!isEmpty(selectedOffer)) {
            const roomType = this.roomTypeMap[selectedOffer.roomTypeId];
            const ratePlan = this.ratePlanMap[selectedOffer.ratePlanId];
            const offer = this.selectedQuoteOffer;

            this.$gtag.query('event', 'add_to_cart', {
              'items': [
                {
                  'id': `${selectedOffer.roomTypeId}/${selectedOffer.ratePlanId}`,
                  'name': `${roomType.name[this.hotel.details.primaryLanguage]} / ${ratePlan.name[this.hotel.details.primaryLanguage]}`,
                  'category': 'Product',
                  'quantity': 1,
                  'price': offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.amount + offer.shoppingCartSummary.excludedTaxAmount,
                },
              ],
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    trackRemoveFromCart() {
      try {
        if (this.trackingEnabled) {
          const selectedOffer = this.selectedOffer;
          if (!isEmpty(selectedOffer)) {
            const roomType = this.roomTypeMap[selectedOffer.roomTypeId];
            const ratePlan = this.ratePlanMap[selectedOffer.ratePlanId];
            const offer = this.selectedQuoteOffer;

            this.$gtag.query('event', 'remove_from_cart', {
              'items': [
                {
                  'id': `${selectedOffer.roomTypeId}/${selectedOffer.ratePlanId}`,
                  'name': `${roomType.name[this.hotel.details.primaryLanguage]} / ${ratePlan.name[this.hotel.details.primaryLanguage]}`,
                  'category': 'Product',
                  'quantity': 1,
                  'price': offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + offer.shoppingCartSummary.amount + offer.shoppingCartSummary.excludedTaxAmount,
                },
              ],
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    getCancellationPolicyTooltip(policies) {
      let ret = '<div class="policy-tooltip">';
      const cancellationPolicy = ((policies.quotePolicyDetails || {}).cancellationPolicy || {})[this.locale];
      const noShowPolicy = ((policies.quotePolicyDetails || {}).noShowPolicy || {})[this.locale];
      const freeCancellationPolicy = ((policies.quotePolicyDetails || {}).freeCancellationPolicy || {})[this.locale];
      const freeCancellation = (policies.quotePolicyDetails || {}).freeCancellation;

      // if (freeCancellation === true && !isEmpty(freeCancellationPolicy)) {
      //   ret += `<p>${freeCancellationPolicy}</p>`;
      // }

      if (!isEmpty(cancellationPolicy)) ret += `<p>${cancellationPolicy}</p>`;

      if (!isEmpty(noShowPolicy)) ret += `<p>${noShowPolicy}</p>`;

      ret += '</div>';

      return ret;
    },
    getPrepaymentPolicyTooltip(policies) {
      let ret = '<div class="policy-tooltip">';
      const prepaymentPolicy = ((policies.quotePolicyDetails || {}).prepaymentPolicy || {})[this.locale];

      if (!isEmpty(prepaymentPolicy)) ret += `<p>${prepaymentPolicy}</p>`;

      ret += '</div>';

      return ret;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .policy-tooltip {
    p:not(:first-child) {
      @apply mt-8;
    }
  }

  .backdrop {
    @apply bg-dark fixed top-0 bottom-0 left-0 right-0;
    // opacity: 0.8;
    filter: opacity(80%);
    backdrop-filter: saturate(90%) blur(2px);
    transition: 0.3s opacity;
  }

  .room-modal {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    transition: 0.3s right;

    right: -700px;
    @apply max-w-700;

    .gallery-top {
      @apply w-full absolute left-0 top-0 block;
      height: 80%;
    }

    .gallery-thumbs {
      @apply box-border absolute block bottom-8 left-8 right-8;
      height: calc(20% - (0.8rem * 2));
      width: calc(100% - (0.8rem * 2));
    }

    .gallery-top {
      .swiper-slide {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }

    &--large {
      @media (min-width: 1360px) {
        --max-width: 100vw;

        @media (min-width: 1600px) {
          --max-width: clamp(700px, calc(100vw - 40rem), 2160px);

          .close-button {
            right: calc(100vw - 40rem + 2.4rem);
            right: calc(var(--max-width) + 2.4rem);
            top: 2.4rem;
          }
        }
        right: calc((100vw - 40rem) * -1);
        right: calc(var(--max-width) * -1);
        display: grid;
        //grid-template-columns: 1fr 2fr;
        //grid-template-columns: 1fr 1fr minmax(450px, 700px);
        grid-template-columns: minmax(350px, 1fr) minmax(350px, 1fr) minmax(350px, 1fr);
        //grid-template-rows: 72px calc(100vh - 72px);
        grid-template-rows: 100vh auto;
        max-width: calc(100vw - 40rem);
        max-width: var(--max-width);

        background: $grayLight;
        background: $text;

        .details {
          background: white;
          @apply border-l border-grayBlue;
          //@apply pt-128 row-start-1 col-start-1;
        }

        .swiper {
          @apply col-span-2;
          position: sticky;
          top: 0;
          margin: 2.4rem;
          width: calc(100% - (2.4rem * 2));
          box-sizing: border-box;
        }

        .gallery-top {
          height: 88%;
        }

        .gallery-thumbs {
          height: calc(12% - 1.6rem);
          @apply bottom-0 left-0 right-0 w-full;
        }

        .packages {
          @media (max-width: 2300px) {
            //@apply grid-cols-1;
          }
        }
      }
    }

    &.right-0 {
      right: 0px;
    }
  }

  .button {
    @apply bg-primary py-12 px-24 text-white rounded-32 inline-block font-medium text-14 whitespace-no-wrap;
    &:disabled {
      @apply bg-grayBlue cursor-not-allowed;
    }

    &.button {
      &--secondary {
        @apply bg-secondary;
      }
    }
  }

  .packages {
    @apply mt-24;
    @apply grid gap-x-16 gap-y-16;
    @apply grid-cols-1;
    @apply relative;

    @media (min-width: 768px) {
      //@apply grid-cols-2;
    }
  }

  .addons {
    &__list {
      li {
        @apply mb-8 flex gap-x-8;

        &:last-of-type {
          @apply mb-0;
        }
      }
    }
  }

  .package {
    @apply grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto min-content;

    &.disabled {
      @apply border-grayBlue bg-grayLight;
      .package-name, .package-cta {
        @apply border-grayBlue;
      }
      .package-total {
        div {
          @apply text-gray;
        }
      }
    }

    &-policies {
      @apply text-12;

      p:not(:first-child) {
        @apply mt-8;
      }
    }

    &-features {
      &--long {
        .info {
          margin-top: -2px;
          max-height: 20px;
          overflow: visible;
        }

        p {
          @apply mt-16;
        }
        ul {
          @apply mt-16 ml-0;
          list-style-type: '✓';
          list-style-position: outside;
          list-style-image: none;
          list-style: none;

          li {
            @apply pl-20 mb-16;

            &:before {
              @apply text-primary font-semibold inline-block text-16;
              content: '✓';
              width: 2rem;
              margin-left: -2rem;
            }
          }
        }
      }
    }

    .title {
      // required because of potential preferred icon (star)
      @apply pr-24;
    }

    /**
     <span class="package-features--long" v-html="ratePlanMap[offer.ratePlanId].longDescription[locale]" />
              </p>
              <!--
              <ul class="mt-16">
                <li class="flex justify-between mb-16">
                  <div class="package-feature flex">
                    <div class="package-check-icon">
                      <check-icon size="18" class="text-primary mr-12"></check-icon>
                    </div>
                    <span class="text-body-small">1 × gratis Museums- oder Konzerteintritt</span>
                  </div>
     */

    &-preferred-icon {
      @apply absolute top-18 right-16 z-20;
    }
  }

  .description-copy {
    @apply relative overflow-hidden h-auto;
    &.truncated {
      @apply transition-all duration-500 ease-in-out;
      height: 12rem;
      &:before {
        content: '';
        @apply absolute left-0 bottom-0 w-full z-20;
        height: 80%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.02) 9%, rgba(255, 255, 255, 0.06) 17%, rgba(255, 255, 255, 0.12) 24%, rgba(255, 255, 255, 0.2) 31%, rgba(255, 255, 255, 0.29) 37%, rgba(255, 255, 255, 0.39) 44%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.61) 56%, rgba(255, 255, 255, 0.71) 63%, rgba(255, 255, 255, 0.8) 69%, rgba(255, 255, 255, 0.88) 76%, rgba(255, 255, 255, 0.95) 83%, rgba(255, 255, 255, 0.98) 91%, white);
      }
    }
  }

  .read-more {
    -webkit-tap-highlight-color: transparent;
  }

  .feature-list {
    @apply grid gap-x-4 gap-y-8;
    grid-template-columns: 1fr 1fr;
  }

  .feature {
    @apply flex;
  }

  .icon {
    @apply mr-4;
  }

  .swiper {
    @apply w-full relative;
    @include aspect-ratio(10,8);
  }
  .swiper-container {
    @apply w-full h-full;
  }

  .swiper-slide {
    @apply bg-red-200 bg-cover bg-center;
  }

  .swiper-slide-thumb-active {
    @apply relative;
    &:before{
      content: '';
      @apply absolute top-0 left-0 right-0 bottom-0 border-3 border-primary;
    }
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    @apply cursor-pointer;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }

  .description-copy {
    p {
      margin-bottom: 1.3rem;
    }
  }

  .shadow-modal {
    box-shadow: -16px 0 48px 0 rgba(57, 64, 69, 0.24);
  }

  .btn-thumbs-next {
    background-image: linear-gradient(to right, $light, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.88) 76%);
    pointer-events: none;

    button {
      top: 50%;
      transform: translateY(-50%);
      pointer-events: all;
    }
  }

  .btn-thumbs-prev {
    background-image: linear-gradient(to left, $light, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.88) 76%);
    pointer-events: none;

    button {
      top: 50%;
      transform: translateY(-50%);
      pointer-events: all;
    }
  }

  .btn-gallery-next, .btn-gallery-prev {
    pointer-events: none;

    button {
      top: 50%;
      transform: translateY(-50%);
      pointer-events: all;
    }
  }
</style>
