/* eslint-disable vue/no-unused-components */
<template>
  <div v-if="roomRequest && hotelDataUpToDate" :key="roomRequestId" class="select-none">
    <!-- <room-modal></room-modal> -->
    <div class="body-small mb-8">
      <p>{{ $t('roomSelect.headline') }}</p>
    </div>
    <div lass="rooms">
      <div class="description mb-32">
        <h1 class="h1 mt-16">
          {{ $t('roomSelect.nthRoom', [idx + 1]) }}
        </h1>
        <div class="body text-gray">
          <i18n path="roomSelect.roomRequestDescription" tag="p" class="text-12 leading-16 mt-16">
            <template v-slot:adults>
              {{ $tc('roomSelect.roomRequestDescription1', roomRequest.numAdults, [roomRequest.numAdults]) }}
            </template>
            <template v-if="roomRequest.childAgeCategories.length > 0" v-slot:children>
              {{ $tc('roomSelect.roomRequestDescription2', roomRequest.childAgeCategories.length, [roomRequest.childAgeCategories.length]) }}
            </template>
          </i18n>
        </div>
      </div>

      <div class="room-selection">
        <template v-for="roomTypeId in roomTypeIdsByRoomRequest[roomRequest.id]">
          <room-type :key="roomTypeId" :room-type="roomTypeMap[roomTypeId]" />
        </template>

        <!--        <template v-for="roomType in hotel.roomTypes">-->
        <!--          <template v-if="!roomTypeIdsByRoomRequest[roomRequest.id].includes(roomType.id)">-->
        <!--            <room-type :key="roomType.id" :room-type="roomType" :disabled="true" />-->
        <!--          </template>-->
        <!--        </template>-->
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, ClockIcon, InfoIcon } from 'vue-feather-icons';
import ButtonSquare from '../ButtonSquare/ButtonSquare';
import RoomModal from '../RoomModal/RoomModal';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { isEmpty, isNil } from 'lodash/lang';
import { find, groupBy, keyBy, map, sortBy } from 'lodash/collection';
import { findIndex, head, indexOf, uniq } from 'lodash/array';
import { minBy } from 'lodash/math';
import RoomType from '@/components/lib/RoomSelect/RoomType';
import RoomSelectMixin from '@/mixins/RoomSelectMixin';

export default {
  name: 'RoomSelect',
  components: {
    ClockIcon,
    CheckIcon,
    InfoIcon,
    RoomType,
  },

  mixins: [BookingEngineMixin, RoomSelectMixin],

  props: {

  },

  data: function () {
    return {
      roomRequestId: null,
      reloadingHotelData: false,
    };
  },

  computed: {
    selectedRoomTypeId() {
      const roomTypeIds = this.$store.getters.selectedOffers.filter((it) => it.roomRequestId === this.roomRequestId).map((it) => it.roomTypeId);
      return isEmpty(roomTypeIds) ? null : roomTypeIds[0];
    },
    roomRequest() {
      return find(this.$store.getters.roomRequests, (it) => it.id === this.roomRequestId);
    },
    idx() {
      return findIndex(this.$store.getters.roomRequests, (it) => it.id === this.roomRequestId);
    },
    anyRoomsAvailable() {
      if (isNil(this.quoteResponse)) return true;

      return Object.values(this.roomSelectRoomTypeAvailability).filter((it) => it.available > 0).length > 0;
    },
    anyOffersAvailable() {
      if (isNil(this.quoteResponse)) return true;

      return !isEmpty(this.quoteResponse.offers);
    },
    roomTypeIdsByRoomRequest() {
      if (isNil(this.quoteResponse)) return {};

      const sortedRoomTypeIds = sortBy(this.hotel.roomTypes, ['sort', (it) => (isNil(it.name) ? null : it.name[this.locale])]).map((it) => it.id);

      const availabilityByRoomType = keyBy(this.roomSelectRoomTypeAvailability, 'roomTypeId');

      const ret = groupBy(this.quoteResponse.offers, 'roomRequestId');
      Object.keys(ret)
        .forEach((roomRequestId) => {
          let roomTypeIds = uniq(map(ret[roomRequestId], 'roomTypeId').filter((it) => !isNil(availabilityByRoomType[it])));
          roomTypeIds = sortBy(
            roomTypeIds,
            [
              (it) => (availabilityByRoomType[it].available || 0) > 0 ? 0 : 100,
              (it) => it === this.preferredRoomTypeId ? 0 : 100,
              (it) => sortedRoomTypeIds.indexOf(it),
            ],
          );
          ret[roomRequestId] = roomTypeIds;
        });

      return ret;
    },
  },

  watch: {
    hotelDataUpToDate: function (newVal) {
      if (newVal === false && this.reloadingHotelData === false) {
        this.$serviceBus.$emit('reloadHotel');
        this.reloadingHotelData = true;
      }
    },
    'anyOffersAvailable': function (newVal) {
      if (newVal === false) {
        this.$serviceBus.$emit('infoBox.msg', this.$t('roomSelect.noOffersFound'));
        this.$store.dispatch('unselectOffer', this.roomRequestId);
        this.$router.push({ name: 'roomcount' });
      }
    },
    '$route.params.roomRequestId': function () {
      this.roomRequestId = this.$route.params.roomRequestId;
    },
  },

  created: function () {
    this.roomRequestId = this.$route.params.roomRequestId;

    if (this.anyOffersAvailable === false || isNil(this.roomRequest)) {
      this.$serviceBus.$emit('infoBox.msg', this.$t('roomSelect.noOffersFound'));
      this.$store.dispatch('unselectOffer', this.roomRequestId);
      this.$router.push({ name: 'roomcount' });
    }
  },

  mounted: function () {
    this.mounted = true;
    window.scroll({ top: 0, behavior: 'smooth' });
    this.track();
  },

  methods: {
    openModal(roomTypeId) {
      this.$router.push({
        name: 'rateselect',
        params: {
          roomRequestId: this.roomRequestId,
          roomTypeId: roomTypeId,
        },
      });
    },
    track() {
      try {
        if (this.trackingEnabled) {
          this.$gtag.query('event', 'view_item_list', {
            'items': this.roomTypeIdsByRoomRequest[this.roomRequest.id].map((roomTypeId) => {
              const roomType = this.roomTypeMap[roomTypeId];
              return {
                'id': roomTypeId,
                'name': roomType.name[this.hotel.details.primaryLanguage],
                'list_position': this.roomTypeIdsByRoomRequest[this.roomRequest.id].indexOf(roomTypeId),
                'category': 'RoomType',
              };
            }),
          });
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .room-selection {
    @apply grid gap-24;

    @media (min-width: 1280px) {
      @apply grid-cols-2;
    }
  }

</style>
