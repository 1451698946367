<template>
  <room-modal />
</template>

<script>
import RoomModal from '@/components/lib/RoomModal/RoomModal';

export default {
  name: 'RoomModalView',
  components: {
    RoomModal,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
